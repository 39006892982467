import { Button } from "@mui/material";
import { useField, useFormikContext } from "formik";
import React from "react";
import { StyledFormHeader, StyledFormSectionRows } from "src/components/Form";
import {
  CheckboxField,
  PhoneField,
  SampleIdField,
  TextField,
} from "src/components/FormComponents";
import {
  FormPreviewCheckboxField,
  FormPreviewHeader,
  FormPreviewSection,
  FormPreviewTextField,
} from "src/components/FormPreview";
import { SampleJson } from "src/models/sample";
import * as Yup from "yup";

export interface ReportToDetailsFormSection {
  sample_id: string;
  report_to_name: string;
  report_to_email: string;
  report_cc_email: string;
  report_to_address_street: string;
  report_to_address_city: string;
  report_to_address_state: string;
  report_to_address_zip: string;
  report_to_phone_number: string;
  report_to_fax_number: string;
  report_to_health_department: boolean;
  report_to_department_of_ag: boolean;

  hd_water_supply_serial_number: string;
  hd_well_permit_number: string;
  hd_property_tax_id: string;
}

export const ReportToSchema = Yup.object({
  report_to_name: Yup.string().required("Required"),
  report_to_email: Yup.string().required("Required"),
  report_to_address_street: Yup.string().required("Required"),
  report_to_address_city: Yup.string().required("Required"),
  report_to_address_state: Yup.string().required("Required"),
  report_to_address_zip: Yup.string().required("Required"),
  report_to_phone_number: Yup.string().required("Required"),

  hd_water_supply_serial_number: Yup.string().when("custom_fields.well_type", {
    is: (v: string) => v === "public",
    then: (schema) => schema.required("Required"),
  }),
});

const ReportToDetails: React.FC<{ isPreview?: boolean }> = ({ isPreview }) =>
  isPreview ? <ReportToDetailsPreview /> : <ReportToDetailsForm />;

const ReportToDetailsForm: React.FC = () => (
  <>
    <CopyToReportingButton />
    <StyledFormHeader>Report Results To</StyledFormHeader>
    <StyledFormSectionRows>
      <SampleIdField
        id="sample_id"
        name="sample_id"
        label="Sample Id"
        disabled
      />
      <TextField id="report_to_name" name="report_to_name" label="Name" />
      <TextField id="report_to_email" name="report_to_email" label="Email" />
      <TextField
        id="report_cc_email"
        name="report_cc_email"
        label="CC Emails"
      />
      <TextField
        id="report_to_address_street"
        name="report_to_address_street"
        label="Address"
      />
      <TextField
        id="report_to_address_city"
        name="report_to_address_city"
        label="City"
      />
      <TextField
        id="report_to_address_state"
        name="report_to_address_state"
        label="State"
      />
      <TextField
        id="report_to_address_zip"
        name="report_to_address_zip"
        label="Zip"
      />
      <PhoneField
        id="report_to_phone_number"
        name="report_to_phone_number"
        label="Phone"
      />
      <PhoneField
        id="report_to_fax_number"
        name="report_to_fax_number"
        label="Fax"
      />
      <br />
      <ReportToExternal />
    </StyledFormSectionRows>
  </>
);

const ReportToExternal: React.FC = () => {
  const [, wellType] = useField("custom_fields.well_type");
  const [, newWell] = useField("custom_fields.new_well");
  const [, reportToDeptOfHealth] = useField("report_to_health_department");

  if (wellType.value === "private") {
    return (
      <>
        <CheckboxField
          id="is_new_well"
          name="custom_fields.new_well"
          label="Is this a new Well?"
          fullWidth
        />
        {newWell.value && (
          <>
            <CheckboxField
              id={`report_to_health_department`}
              name={`report_to_health_department`}
              label="Report To Health Department?"
              fullWidth
            />
            {reportToDeptOfHealth.value && (
              <>
                <TextField
                  id="hd_well_permit_number"
                  name="hd_well_permit_number"
                  label="Well Permit #"
                />
                <TextField
                  id="hd_property_tax_id"
                  name="hd_property_tax_id"
                  label="Property Tax ID #"
                />
              </>
            )}
          </>
        )}
      </>
    );
  } else if (wellType.value === "public") {
    return (
      <>
        <CheckboxField
          id={`report_to_health_department`}
          name={`report_to_health_department`}
          label="Report To EGLE/Health Department?"
          fullWidth
        />
        <CheckboxField
          id={`report_to_department_of_ag`}
          name={`report_to_department_of_ag`}
          label="This is a Department of Agriculture, GAP, MAEAP, FSMA, or Primus Project?"
          fullWidth
        />
        <TextField
          id="hd_water_supply_serial_number"
          name="hd_water_supply_serial_number"
          label="WSSN"
        />
      </>
    );
  } else {
    return null;
  }
};

const ReportToDetailsPreview: React.FC = () => {
  const [, wellType] = useField("custom_fields.well_type");
  const [, newWell] = useField("custom_fields.new_well");
  const [, reportToDeptOfHealth] = useField("report_to_health_department");

  return (
    <>
      <FormPreviewSection>
        <FormPreviewHeader>Report Results To</FormPreviewHeader>
        <div>
          <FormPreviewTextField
            id="report_to_name"
            name="report_to_name"
            label="Name"
          />
          <FormPreviewTextField
            id="report_to_email"
            name="report_to_email"
            label="Email"
          />
          <FormPreviewTextField
            id="report_cc_email"
            name="report_cc_email"
            label="CC Emails"
          />
          <FormPreviewTextField
            id="report_to_address_street"
            name="report_to_address_street"
            label="Address"
          />
          <FormPreviewTextField
            id="report_to_address_city"
            name="report_to_address_city"
            label="City"
          />
          <FormPreviewTextField
            id="report_to_address_state"
            name="report_to_address_state"
            label="State"
          />
          <FormPreviewTextField
            id="report_to_address_zip"
            name="report_to_address_zip"
            label="Zip"
          />
          <FormPreviewTextField
            id="report_to_phone_number"
            name="report_to_phone_number"
            label="Phone"
          />
          <FormPreviewTextField
            id="report_to_fax_number"
            name="report_to_fax_number"
            label="Fax"
          />
          {wellType.value === "private" && (
            <>
              <FormPreviewCheckboxField
                id="report_to_health_department"
                name="report_to_health_department"
                label="Report To Health Department?"
              />
              {reportToDeptOfHealth.value && (
                <>
                  <FormPreviewTextField
                    id="hd_well_permit_number"
                    name="hd_well_permit_number"
                    label="Well Permit #"
                  />
                  <FormPreviewTextField
                    id="hd_property_tax_id"
                    name="hd_property_tax_id"
                    label="Property Tax ID #"
                  />
                </>
              )}
            </>
          )}
          {wellType.value === "public" && (
            <>
              <FormPreviewCheckboxField
                id="report_to_health_department"
                name="report_to_health_department"
                label="Report To EGLE/Health Department?"
              />
              <FormPreviewCheckboxField
                id={`report_to_department_of_ag`}
                name={`report_to_department_of_ag`}
                label="This is a Department of Agriculture, GAP, MAEAP, FSMA, or Primus Project?"
              />
              <FormPreviewTextField
                id="hd_water_supply_serial_number"
                name="hd_water_supply_serial_number"
                label="WSSN"
              />
            </>
          )}
        </div>
      </FormPreviewSection>
    </>
  );
};

const CopyToReportingButton: React.FC = () => {
  const { values, setValues } = useFormikContext<SampleJson>();

  function copyReportingAddress(values: SampleJson, setValues: Function) {
    setValues((currentValues: any) => ({
      ...currentValues,
      report_to_name: values.site_owner,
      report_to_address_street: values.site_address_street,
      report_to_address_city: values.site_address_city,
      report_to_address_state: values.site_address_state,
      report_to_address_zip: values.site_address_zip,
    }));
  }

  return (
    <Button
      className="use-repo-address label-full-width"
      color="primary"
      size="small"
      onClick={() => copyReportingAddress(values, setValues)}
    >
      Copy To Reporting Address
    </Button>
  );
};

export default ReportToDetails;
